.home {
    background-color: #2A9D8F;
}

.home h1 {
    color: white;
}

.profile-picture {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    height: 500px;
    width: 500px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    border-style: solid;
    border-color: #E76F51;
    border-width: 15px;
}

.profile-picture svg {
    display: inline;
    margin: auto;
    height: 500px;
    width: 500px;
}

/* Responsive navigation menu (for mobile devices) */
@media screen and (max-height: 768px) {
    .profile-picture {
        height: 400px;
        width: 400px;
    }
    
    .profile-picture svg {
        height: 400px;
        width: 400px;
    }    
}

/* Responsive navigation menu (for mobile devices) */
@media screen and (max-width: 600px) {
    .profile-picture {
        height: 200px;
        width: 200px;
    }

    .profile-picture svg {
        height: 200px;
        width: 200px;
    }    
}


