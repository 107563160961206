.About {
    /* background-color: #02A9EA; */
    background-color: #2A9D8F;
}

.row {
    display: flex;
}

.column{
    flex: 50%;
}

.left{
    display: inline;
    float: none;
}

.right svg {
    padding: 50px 50px 50px 200px;
    display: inline;
    margin: auto;
    height: 250px;
    width: 250px;
}

