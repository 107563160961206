.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #264653;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.topnav a:hover,
.topnav a:hover svg{
  background-color: #E76F51;
}

.topnav a:active, 
.topnav a:active svg{
  background-color: #F4A261;
}

.footer {
  background-color: #E9C46A;
  height:200px;
}


/* Responsive navigation menu (for mobile devices) */
@media screen and (max-width: 600px) {
  .topnav{
    background-color: #264653;
    overflow: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .topnav a {
    float: left;
    padding: 12px 0 12px 0;
    color: white;
    text-decoration: none;
    font-size: 17px;
    width: 20%; /* Four links of equal widths */
    text-align: center;
  }

  .topnav svg{
    display: block;
    margin: auto;
    color: white;
    padding: 5px 0 0 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
